@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;900&display=swap);
:root {
  --background:	#282a36;
  --backgroundrgb:	rgb(40, 42, 54);
  --current-line:	#44475a;
  --foreground:	#f8f8f2;
  --comment:	#6272a4;
  --cyan:	#8be9fd;
  --green:	#50fa7b;
  --orange:	#ffb86c;
  --pink:	#ff79c6;
  --purple:	#bd93f9;
  --red:	#ff5555;
  --yellow:	#f1fa8c;
  font-size: 62.5%;
}

* { 
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #282a36;
  background: var(--background);
  color: #f8f8f2;
  color: var(--foreground);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
}

body, #root {
  max-width: 100vw;
  max-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

header {
  background: #6272a4;
  background: var(--comment);
  padding: 1.5rem;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
}

header div {  
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

header h1 a {
  font-size: 4rem;
  color: #f8f8f2;
  color: var(--foreground);
  text-decoration: none;
}

header h1 a:hover {
  text-decoration: none;
}

header form { 
	border-radius: 10px;
	background: #f8f8f2;
	background: var(--foreground);
	padding: 1.2rem;
	display: flex;
  justify-content: space-between;
}

header .search { 
	width: 100%;
	margin: 0;
	padding: 5px 9px;
	border: none ! important;
	box-shadow: none;
	background: #f8f8f2;
	background: var(--foreground);
	font-size: 14px;
  color: #44475a;
  color: var(--current-line);
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
  border: none;
}
header .search:focus, .search-submit:focus { 
  outline: none;
}

header .search-submit { 
	border: none; 
	background: none;
}

header .search-submit svg {
	opacity: .5;
	transition: all 500ms cubic-bezier(0, 1.24, 0, 1.33);
}

header:focus-within .search-submit svg {
 transform: scale(1.5);
 color: #282a36;
 color: var(--background);
 opacity: 1;
}

.movie-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 1400px;
  margin: auto;
  margin-top: 12rem;

}

.movie {
  width: 250px;
  border-radius: 5px;
  /* overflow: hidden; */
  background: #44475a;
  background: var(--current-line);
  box-shadow: 2px 2px 1px rgba(0,0,0,0.2);
  position: relative;
  cursor: pointer;
}

.movie img {
  width: 100%;
  height: 375px;
  object-fit: cover;
  z-index: 0;
}

.movie-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0;
  font-weight: bold;
}

.movie-info h2 {
  margin: 0;
  padding: 0;
}

.movie-info span {
  border-radius: 5px;
  background: #282a36;
  background: var(--background);
  padding: .5rem;
}
.green {
  color: #50fa7b;
  color: var(--green);
}
.orange {
  color: #ffb86c;
  color: var(--orange);
}
.red {
  color: #ff5555;
  color: var(--red);
}



.front {
  transition: 250ms
}

.back {
  opacity: 0;
  position: absolute;
  top: 55%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1.5em;
  gap: 1.5em;
  transform: translateY(10%);
 }

.background {
  background: #44475a;
  background: var(--current-line);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transform: scale(.2, .9);
  opacity: 0;
  border-radius: 5px;
  overflow: hidden;
}

.background img {
  opacity: .3;
  -webkit-clip-path: url(#wave);
          clip-path: url(#wave);
  height: 30%;
  width: 100%;
  object-fit: cover;

}

.movie:hover {
  z-index: 1000000;
}

.movie:hover .front {
  transform: translateY(-30%) scale(.75)
}

.movie:hover .back {
  opacity: 1;
  transition: transform 250ms ease, opacity 150ms linear;
  transform: translateY(0);
}
.back p {
  overflow: hidden;
  height: 100px;
}


.movie:hover .back p {
  margin-top: -30px;
  padding: 0 20px 20px 20px;
  overflow: auto;
  height: 200px;
  opacity: .9;
}

.back p::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(40, 42, 54, 0.3);
  border-radius: 10px;
  background-color: #f8f8f2;
  background-color: var(--foreground);
}
.back p::-webkit-scrollbar {
  width: 5px;
  background-color: #f8f8f2;
  background-color: var(--foreground);

}
.back p::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(40, 42, 54, .3);
  background-color: #bd93f9;
  background-color: var(--purple);
}



.movie:hover .background {
  transition:  
    var(--transition),
    opacity 100ms linear;
  opacity: 1;
  transform: scale(1, 1);
}

.movie:hover .movie-info {
  opacity: 0;
}

.movie:hover .front img {
  transform: scale(0.8);
}

footer {
  padding: 50px;
  background: #6272a4;
  background: var(--comment);
  text-align: center;
  margin-top: 40px;
}

.not-found {
  padding: 3rem;
  border-radius: 5px;
  background: #f1fa8c;
  background: var(--yellow);
  color: #282a36;
  color: var(--background);
  text-align: center;
  opacity: .9;
}

@media (max-width:470px) {
  header h1 a {
    font-size: 2rem;
  }
}
